import React, { useState, useEffect, useRef } from 'react';
import { FaStar, FaUserCheck, FaProjectDiagram, FaBriefcase } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';


function Home() {
  function Counter({ end, duration }) {
    const [count, setCount] = useState(0);
    const countRef = useRef(null);

    useEffect(() => {
      const startTime = Date.now();
      const timer = setInterval(() => {
        const timePassed = Date.now() - startTime;
        const progress = Math.min(timePassed / duration, 1);
        setCount(Math.floor(progress * end));
        if (progress === 1) clearInterval(timer);
      }, 50);

      return () => clearInterval(timer);
    }, [end, duration]);

    return <span ref={countRef}>{count}</span>;
  }

  return (
    <div className="home">
      <div className="hero-section">
        <div className="column left">
          <h1 className="slogan">
            <FaStar className="icon" />
            Innovate. Create. Elevate.
          </h1>
        </div>
        <div className="column right">
          <p className="subtext">
            Adastra Studios develops the most sophisticated immersive experiences and technology implementations in AI/AR/MR/VR/VFX, enterprise pipeline, and workflow solutions.
          </p>
          <div className="flexbox">
            <NavLink to="/projects">
              <button className="homebtn outlinebtn">Check our projects</button>
            </NavLink>
            <NavLink to="/contact">
              <button className="homebtn filledbtn">Contact us</button>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="stats-section">
        <div className="stat-item">
          <FaUserCheck className="stat-icon" />
          <div className="stat-count"><Counter end={100} duration={2000} />+</div>
          <div className="stat-label">Satisfied Customers</div>
        </div>
        <div className="stat-item">
          <FaProjectDiagram className="stat-icon" />
          <div className="stat-count"><Counter end={50} duration={2000} />+</div>
          <div className="stat-label">Completed Projects</div>
        </div>
        <div className="stat-item">
          <FaBriefcase className="stat-icon" />
          <div className="stat-count"><Counter end={5} duration={2000} />+</div>
          <div className="stat-label">Years in Business</div>
        </div>
      </div>
      <div style={{height: 20, width: 1}}>&nbsp;</div>
    </div>
  );
}

export default Home;