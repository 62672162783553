import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Home from './Home';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';

import './App.css';

// Create a context to share the transition direction
const TransitionDirectionContext = React.createContext();

function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setTransitionDirection } = React.useContext(TransitionDirectionContext);

  const pageOrder = ['/', '/about', '/projects', '/contact'];

  const handleClick = (path) => {
    const currentIndex = pageOrder.indexOf(location.pathname);
    const nextIndex = pageOrder.indexOf(path);

    if (nextIndex !== currentIndex) {
      setTransitionDirection(nextIndex > currentIndex ? 'slide-left' : 'slide-right');
    }

    navigate(path);
  };

  return (
    <div className='nav-wrapper'>
      <nav id="nav-full">
        <div className="logo-placeholder">
          <img src="logo.png" alt="Logo" width="80" height="53" style={{padding:"0"}} onClick={() => handleClick('/')} />
        </div>
        <ul>
          <li><NavLink to="/" onClick={() => handleClick('/')}>Home</NavLink></li>
          <li><NavLink to="/about" onClick={() => handleClick('/about')}>About</NavLink></li>
          <li><NavLink to="/projects" onClick={() => handleClick('/projects')}>Projects</NavLink></li>
          <li><NavLink to="/contact" onClick={() => handleClick('/contact')}>Contact</NavLink></li>
        </ul>
      </nav>
      <nav id="nav-mobile">
        <div className='logo-placeholder-mobile'>
          <img src="logo.png" alt="Logo" width="80" height="53" style={{padding:"0"}} onClick={() => handleClick('/')} />
        </div>
        <ul style={{paddingTop: 10}}>
          <li><NavLink to="/" onClick={() => handleClick('/')}>Home</NavLink></li>
          <li><NavLink to="/about" onClick={() => handleClick('/about')}>About</NavLink></li>
          <li><NavLink to="/projects" onClick={() => handleClick('/projects')}>Projects</NavLink></li>
          <li><NavLink to="/contact" onClick={() => handleClick('/contact')}>Contact</NavLink></li>
        </ul>
      </nav>
    </div>
  );
}

function AnimatedRoutes() {
  const location = useLocation();
  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleExited = () => {
    setIsTransitioning(false);
  };

  const handleEnter = () => {
    setIsTransitioning(true);
  };

  return (
    <TransitionGroup className="transition-group" style={ isTransitioning ? { height: 'calc(100vh - 100px)', overflow: 'hidden' } : { height: "100%", overflow: "visible" } }>
      <CSSTransition
        key={location.pathname}
        classNames={React.useContext(TransitionDirectionContext).transitionDirection}
        timeout={300}
        onEnter={handleEnter}
        onExited={handleExited}
      >
        <div className="route-wrapper">
          <div className="route-section">
            <Routes location={location}>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

function App() {
  const [transitionDirection, setTransitionDirection] = useState('slide-left');

  return (
    <Router>
      <div className="demo-container"></div>
        <div className="emscripten_border" style={{width: "100%", height: "100%", border: 0, margin: 0, padding: 0}}>
        <canvas className="emscripten" style={{width: "100%", height: "100%", border: 0}} id="canvas" onContextMenu={ (e) => {e.preventDefault()}}></canvas>
      </div>
      <TransitionDirectionContext.Provider value={{ transitionDirection, setTransitionDirection }}>
        <div className="App">
          <Nav />
          <AnimatedRoutes />
        </div>
      </TransitionDirectionContext.Provider>
    </Router>
  );
}

export default App;
