import React from 'react';
import { NavLink } from 'react-router-dom';

function About() {
  return (
    <div className="about">
      <img src="logo.png" alt="Logo" className="about-img"/>
      <div className="flexbox about-wrapper" style={{alignItems:"first baseline"}}>
        <div className="column left">
          <h2>Who we are</h2>
          <p className="subtext">
          Hubert Krzysztofik is currently CTO at The Virtual Reality Company. He
is presently leading engineering eﬀorts on the Jurassic Park VR Experience. Hubert and his
team received a Lumiere Award for creating “Raising a Ruckus” and Apple’s best AR app in
2017 for “Follow Me Dragon.” He has taken the lead in building the VRC’s world-class VR
content production delivery platform.
Hubert is a Director of Immersive Technology with over 15 years experience developing high
end multimedia applications with extensive experience in app development, digital
workflow, digital pipelines, and cloud computing.
During his time at Pixomondo, Hubert was instrumental in developing virtual reality projects
utilizing and uniting multiple technologies and platforms for clients like Porsche, HBO and
Fiat Group to name the few.<br></br>

Credits Include: American Red Cross, CNN, Direct TV, Lexus, The
Los Angeles Lakers, NASCAR, Duke University, Time Warner Cable,
British Telecom, Cotton Inc., First Citizen’s Bank, Rev Up
Transmedia, Pullin Television, Disruptive Studios, HBO, Alfa Romeo,
Fiat Group, Nissan, Infinity, Speed
          </p>
            <NavLink to="/contact">
            <button className="homebtn filledbtn">Contact us</button>
          </NavLink>
        </div>

        <div className="column right">
          <h2>What we do</h2>
          <p className="subtext">
            Adastra Studios develops the most sophisticated immersive experiences and technology implementations in AI/AR/MR/VR/VFX, enterprise pipeline, and workflow solutions.<br></br>
            • Experts in all aspects of the development circle<br></br>
            • Experience managing teams for AR, VR, and VFX<br></br>
            • Programming, Unreal, Unity, and pipeline<br></br>
            • Eﬀectively manage and execute extremely complex and demanding projects by combining his various skill sets.<br></br>
            • Develop and maintain consumer and professional production-level immersive applications.<br></br>
          </p>
        </div>
      </div>
      <div className="stats-section" style={{display:"block"}}>
        <div style={{float:"left", width:"100%"}} className="flexbox">
          <h1 style={{marginTop:0}}>Collaborators</h1>
        </div>

        <div style={{maxWidth: 500}}>
          <img src="redc.png" className="collab"></img>
          <img src="cnn.png" className="collab"></img>
          <img src="directv.png" className="collab"></img>
          <img src="lexus.png" className="collab"></img>
          <img src="lakers.png" className="collab"></img>
          <img src="nascar.png" className="collab"></img>
          <img src="alfaromeo.png" className="collab"></img>
          <img src="fca.png" className="collab"></img>
          <img src="hbo.png" className="collab"></img>
        </div>
      </div>
    </div>
  );
}

export default About;
