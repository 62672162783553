import React, { useState, useEffect, useRef } from 'react';

const projects = [

  {
    id: 2,
    title: "Jurassic World VR Expedition",
    image: "/jurassic.jpg",
    description: "This cooperation between Dave & Busters, VRC, Steven Spielberg’s Amblin Entertainment, and Universal Studios is the biggest location-based VR deal involving a major movie studio property and was the highest-grossing, location-based VR experience."
  },
  {
    id: 3,
    title: "The Martian VR Experience",
    image: "/martian.jpg",
    description: "‘The Martian VR Experience’ is executive produced by Ridley Scott and directed by Robert Stromberg. It debuted at CES 2016, was accepted into the New Frontier Program at Sundance, and is the recipient of the Cannes Silver Lion in Digital Craft and the Association of Independent Commercial Producers (AICP) Next VR Award."
  },
  {
    id: 4,
    title: "Raising a Rukus Expierence",
    image: "/rukus.jpg",
    description: "2018 Lumiere Award – Best Location Based VR: Animated Twins Amy and Jonas just found Rukus, a mischievous dog – or did he find them? Either way, Rukus has a magical secret – he’s about to take them on a magical, rollercoaster journey through a prehistoric world. Equal parts thrilling, breathtaking, and inspiring, ‘Raising a Rukus’ is an animated adventure like no other.",
  },
  {
    id: 5,
    title: "Follow Me Dragon",
    image: "/dragon.jpg",
    description: "2017 Apple Best AR Award. ‘Follow Me Dragon’ is a truly innovative AR experience populated with hilarious and adorable new characters, and was designed to be enjoyed by participants of all ages.",
  },
  {
    id: 6,
    title: "Vestium Fashion AR",
    image: "/fashion.jpg",
    description: "Fashion app and Platform that leverages VR and AR so sellers can provide a more immersive and engaging shopping experience, allowing buyers to see the items in greater detail and with more accuracy."
  },
  {
    id: 7,
    title: "webgl-es2",
    image: "/gles2.png",
    description: "A fully custom WebGL ES 2.0 rendering engine written in C++, capable of producing high-quality graphics on the web at great performance. The engine is designed to be modular and extensible, allowing for easy integration of new features and rendering techniques.",
  },
  // Add more projects as needed
];

function Projects() {
  const [activeProject, setActiveProject] = useState(0);
  const projectRefs = useRef(projects.map(() => React.createRef()));

  const handleScroll = () => {
    const parent = document.querySelector('.projects-container');
    const windowHeight = parent.clientHeight;

    projectRefs.current.forEach((ref, index) => {
      const element = ref.current;
      if (element) {
        const { top } = element.getBoundingClientRect();
        if (top <= windowHeight / 2) {
          setActiveProject(index);
        }
      }
    });
  };

  useEffect(() => {

  }, []);

  return (
  <div>
    <div className="projects-container" onScroll={handleScroll}>
      {projects.map((project, index) => (
        <>
        <div
          key={project.id}
          ref={projectRefs.current[index]}
          className={`project-fullpage ${index === activeProject ? 'active' : ''}`}
        >
          <div className="project-content">
            <div className="project-left left">
              <h2>{project.title}</h2>
              <img src={project.image} alt={project.title} />
            </div>
            <div className="project-right right">
              <p>{project.description}</p>
              <a href={project.url}>{project.urltext}</a>
            </div>
          </div>
          { (() => { if( window.innerWidth < 800 ) return <div style={{height:500}}></div>; })() }
        </div>
        </>
      ))}
    </div>
    <div className="projects-pages">
      <button key="up" onClick={() => {
          let active = activeProject;
          if( active === 0 ) active = projects.length - 1;
          else --active;
          setActiveProject(active);
          projectRefs.current[active].current.scrollIntoView({ behavior: 'smooth' });
        }}
        className={`pagination-btn`}
        style={{ marginLeft: -3 }}
      >
        ⮝
      </button>
      { /* pagination */ }
      {projects.map((project, index) => (
        <button
          key={project.id}
          onClick={() => {
            setActiveProject(index);
            projectRefs.current[index].current.scrollIntoView({ behavior: 'smooth' });
          }}
          className={`pagination-btn ${index === activeProject ? 'active' : ''}`}
        >
        |
        </button>
      ))}
      <button key="down" onClick={() => {
          let active = activeProject;
          if( active === projects.length - 1 ) active = 0;
          else ++active;
          setActiveProject(active);
          projectRefs.current[active].current.scrollIntoView({ behavior: 'smooth' });
        }}
        className={`pagination-btn`}
        style={{ marginLeft: -3 }}
      >
        ⮟
      </button>
    </div>
  </div>
  );
}

export default Projects;
