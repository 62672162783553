import React from 'react';
import { NavLink } from 'react-router-dom';

function Contact() {
  return (
    <div className="contact">
      <img src="logo.png" alt="Logo" className="about-img" width="50%" />
      <div className="flexbox contact-main" style={{alignItems:"first baseline"}}>
        <div className="column left">
          <h2>Leave us a message</h2>
          <form action="/send-email" method="POST">
            <label for="name">Name:</label><br/>
            <input type="text" id="name" name="name" required/><br/>

            <label for="email">Email:</label><br/>
            <input type="email" id="email" name="email" required/><br/>

            <label for="subject">Subject:</label><br/>
            <input type="text" id="subject" name="subject" required/><br/>

            <label for="message">Message:</label><br/>
            <textarea id="message" name="message" rows="4" cols="50" required>
            </textarea><br/>

            <input type="submit" value="Send"/>
          </form>
        </div>
        <div className="column right">
          <h2>Contact information</h2>
          <p className="subtext">
            <span style={{fontWeight:"bold", fontSize: 21}}>Team Lead</span><br/>
            &nbsp;Hubert Krzysztofik <br/>{/*<a style={{color:"var(--accent-color)"}} href={"malto:info@adastrastudios.com"}>info@adastrastudios.com</a><br/>*/}

            <span style={{fontWeight:"bold", fontSize: 21}}>Development Lead</span><br/>
            &nbsp;Alex Priebe <br/>{/*<a style={{color:"var(--accent-color)"}} href={"mailto:admin@moneybot.cc"}>admin@moneybot.cc</a><br/>*/}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
